"use client";

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import { CheckCircle, Warning } from "@phosphor-icons/react/dist/ssr";
import FeatherIcon from "feather-icons-react";

export function Toaster() {
  const { toasts } = useToast();

  const toastIcon = (
    variant:
      | "default"
      | "danger"
      | "success"
      | "warning"
      | "info"
      | null
      | undefined,
  ) => {
    switch (variant) {
      case "success":
        return <CheckCircle size={24} className="text-success" />;
      case "danger":
        return (
          <FeatherIcon
            icon="alert-triangle"
            size={24}
            className="text-danger"
          />
        );
      case "warning":
        return <Warning size={24} className="text-warning" />;
      case "info":
        return <FeatherIcon icon="info" size={24} className="text-info" />;
      default:
        return null;
    }
  };

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="flex gap-x-4">
              {props?.variant && props?.variant !== "default" && (
                <div className="mt-0.5 w-6 shrink-0">
                  {toastIcon(props?.variant)}
                </div>
              )}
              <div className="grid flex-1 gap-1">
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && (
                  <ToastDescription>{description}</ToastDescription>
                )}
                <div>{action}</div>
              </div>
            </div>
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
