import { cn } from "@/lib/utils";
import React, { SVGProps } from "react";

const PremiumQueenIcon = ({
  width,
  height,
  className,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
      width={width}
      height={height}
      {...props}
    >
      <path
        d="M9.50005 5.27783C9.50005 5.79893 9.58388 6.3003 9.73879 6.76925L8.69672 7.25808C7.83282 6.36259 6.62037 5.80562 5.27783 5.80562C2.65448 5.80562 0.527832 7.93226 0.527832 10.5556C0.527832 12.8941 2.21767 14.8379 4.44284 15.2325L6.94561 23.8134C6.99785 23.9925 7.07361 24.1639 7.1709 24.3231L8.61338 26.6835L8.60451 26.6851C7.59611 26.8635 6.86117 27.7399 6.86117 28.7639V32.7223C6.86117 33.8882 7.80634 34.8334 8.97227 34.8334L29.0684 34.8334C29.6283 34.8334 30.1653 34.611 30.5612 34.2151C30.9571 33.8191 31.1795 33.2822 31.1795 32.7223V28.7639C31.1795 27.7387 30.4429 26.8617 29.4331 26.6846L29.3906 26.6771L30.8292 24.3231C30.9265 24.1639 31.0023 23.9925 31.0545 23.8134L33.5573 15.2325C35.7824 14.8379 37.4723 12.8941 37.4723 10.5556C37.4723 7.93226 35.3456 5.80562 32.7223 5.80562C31.378 5.80562 30.1642 6.36399 29.3001 7.26146L28.2599 6.7735C28.4157 6.30333 28.5001 5.8005 28.5001 5.27783C28.5001 2.65448 26.3734 0.527832 23.7501 0.527832C21.1267 0.527832 19.0001 2.65448 19.0001 5.27783C19.0001 2.65448 16.8734 0.527832 14.2501 0.527832C11.6267 0.527832 9.50005 2.65448 9.50005 5.27783Z"
        fill="#F4F7FA"
      />
      <path
        d="M15.197 7.74134C16.1865 7.36067 16.8887 6.40112 16.8887 5.27756C16.8887 3.82014 15.7072 2.63867 14.2498 2.63867C12.7924 2.63867 11.6109 3.82014 11.6109 5.27756C11.6109 6.62437 12.6198 7.73553 13.9231 7.89643L12.7463 17.4164L7.07317 12.4891C7.5919 12.0073 7.91645 11.3192 7.91645 10.5553C7.91645 9.09793 6.73498 7.91646 5.27756 7.91646C3.82014 7.91646 2.63867 9.09793 2.63867 10.5553C2.63867 12.0128 3.82014 13.1942 5.27756 13.1942C5.53412 13.1942 5.78213 13.1576 6.01664 13.0893L8.97201 23.222L12.0282 28.223L8.97201 28.7637V32.722L29.0682 32.722V28.7637L25.9728 28.2208L29.0276 23.222L31.9829 13.0893C32.2174 13.1576 32.4654 13.1942 32.722 13.1942C34.1794 13.1942 35.3609 12.0128 35.3609 10.5553C35.3609 9.09793 34.1794 7.91646 32.722 7.91646C31.2646 7.91646 30.0831 9.09793 30.0831 10.5553C30.0831 11.3192 30.4077 12.0073 30.9264 12.4891L25.2533 17.4164L24.0764 7.89643C25.3797 7.73553 26.3887 6.62437 26.3887 5.27756C26.3887 3.82014 25.2072 2.63867 23.7498 2.63867C22.2924 2.63867 21.1109 3.82014 21.1109 5.27756C21.1109 6.40112 21.8131 7.36067 22.8025 7.74134L18.9998 16.3609L15.197 7.74134Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PremiumQueenIcon;
