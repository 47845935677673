import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import FeatherIcon from "feather-icons-react";
import { Button } from "./ui/button";
import { CaretRight } from "@phosphor-icons/react/dist/ssr";
import { cn } from "@/lib/utils";
import { useRouter } from "next/navigation";
import { useState } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
const NavbarSearch = () => {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState("");

  const formSchema = z.object({
    q: z.string().min(1, { message: "This field has to be filled." }),
  });

  type ValidationSchema = z.infer<typeof formSchema>;
  const form = useForm<ValidationSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      q: "",
    },
  });
  const {
    control,
    formState: { errors },
  } = form;
  const handleClick = () => {
    console.log(searchTerm);
  };
  const onSubmit = (values: ValidationSchema) => {
    // router.push(`/arama?q=${searchTerm}`);
  };
  return (
    <Popover>
      <PopoverTrigger
        className={cn(
          "navbar-popover flex h-full w-16 items-center justify-center bg-white text-black hover:bg-primary hover:text-white",
        )}
      >
        <FeatherIcon icon="search" size={24} />
        <span className="sr-only">Arama</span>
      </PopoverTrigger>
      <PopoverContent
        className="flex h-[60px] w-full items-center rounded-none rounded-b-md border-none bg-primary p-0 px-4"
        sideOffset={0}
        align="end"
      >
        <Form {...form}>
          <form
            action={`/arama?q=${searchTerm}`}
            className="flex flex-row justify-between"
            onSubmit={() => form.handleSubmit(onSubmit)}
          >
            <FormField
              control={control}
              name="q"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="text"
                      className={cn(
                        "placeholder:text-c-neutral-cool-gray border-c-neutral-light-gray text-c-primary-marine-blue  placeholder:font-medium",
                        {
                          "border-c-primary-strawberry-red": errors.q?.message,
                        },
                      )}
                      {...field}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.currentTarget.value)}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <Button
              variant="text"
              className="ml-2 h-full w-[40px] px-0 text-white hover:text-neutral-300"
              onClick={() => onSubmit(form.getValues())}
            >
              <CaretRight size={24} className="text-white" />
            </Button>
          </form>
        </Form>
      </PopoverContent>
    </Popover>
  );
};

export default NavbarSearch;
