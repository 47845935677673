import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { User } from "@phosphor-icons/react/dist/ssr";
import Link from "next/link";
import { cn } from "@/lib/utils";

import { Button } from "./ui/button";
import NotificationBellIcon from "./svg-icons/notification-bell-icon";
import StarFourIcon from "./svg-icons/star-four-icon";
import CircleIcon from "./svg-icons/circle-icon";
import axios from "axios";

import { useEffect, useRef, useState } from "react";
import { set } from "date-fns";

const NavbarVideos = () => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  function handleOnClick() {
    setAlertOpen(!alertOpen);
  }

  const handleMouseEnter = () => {
    setAlertOpen(true);
  };

  const handleMouseLeave = () => {
    setAlertOpen(false);
  };

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setAlertOpen(false);
    }
  };
  const handleClickOutside = (event: Event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setAlertOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <Popover open={alertOpen}>
      <PopoverTrigger
        //  onClickCapture={openAlert}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={cn(
          "navbar-popover flex h-full w-20 items-center justify-center bg-white text-black hover:bg-primary hover:text-white",
        )}
      >
        Videolar
        <span className="sr-only">Videolar</span>
      </PopoverTrigger>
      <PopoverContent
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="rounded-none rounded-b-md border-none bg-primary p-0 py-3 lg:w-[160px]"
        sideOffset={0}
        align="end"
        ref={wrapperRef}
      >
        <ul className="flex w-full flex-col text-right text-white">
          <li className="block w-full px-4 py-1 text-right text-white hover:bg-[#372980]">
            <Link
              onClickCapture={handleOnClick}
              href={"/seri/egitimler"}
              prefetch={true}
            >
              Eğitimler
            </Link>
          </li>
          <li className="block w-full px-4 py-1 text-right text-white hover:bg-[#372980]">
            <Link
              onClickCapture={handleOnClick}
              href={"/seri/eglence"}
              prefetch={true}
            >
              Eğlence
            </Link>
          </li>
        </ul>
      </PopoverContent>
    </Popover>
  );
};

export default NavbarVideos;
